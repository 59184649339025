import * as bairdResearchService from "#app/_api/baird-research-service";
import * as financialPlansService from "#app/_api/financial-plans-service";
import * as performanceService from "#app/_api/performance-reports-service";
import * as trustService from "#app/_api/trust-service";
import * as userProfileService from "#app/_api/user-profile-service";
import { useQuery } from "@tanstack/react-query";

export interface RequiredData {
  hasBairdResearch: boolean;
  canUseFinancialPlanning: boolean;
  hasVisiblePlans: boolean;
  hasApl: boolean;
  canUseTrust: boolean;
}

interface RequiredDataQueryResult {
  isPendingRequiredData: boolean;
  requiredData: RequiredData;
}

export function useRequiredData() {
  const resultAccountGroups = useQuery(
    userProfileService.useAccountGroupsQueryOptions(),
  );
  const resultFinancialPlanStatus = useQuery(
    financialPlansService.useFinancialPlansStatusQueryOptions(),
  );
  const resultHasApl = useQuery(performanceService.useHasAplQueryOptions());
  const resultAccountNickName = useQuery(
    userProfileService.useAccountNickNameQueryOptions(),
  );
  const resultBairdResearchAccess = useQuery(
    bairdResearchService.useQueryOptionsResearchHeadlinesHasAccess(),
  );
  const resultEligibilityMenu = useQuery(
    trustService.useTrustEligibilityMenuQueryOptions(),
  );

  return {
    isPendingRequiredData: [
      resultAccountGroups,
      resultFinancialPlanStatus,
      resultHasApl,
      resultAccountNickName,
      resultBairdResearchAccess,
      resultEligibilityMenu,
    ].some((result) => result.isPending),

    requiredData: {
      hasBairdResearch: Boolean(resultBairdResearchAccess.data),

      canUseFinancialPlanning: Boolean(
        resultFinancialPlanStatus?.data?.canUseFinancialPlanning,
      ),

      hasVisiblePlans: Boolean(
        resultFinancialPlanStatus?.data?.hasVisiblePlans,
      ),

      hasApl: Boolean(resultHasApl?.data),

      canUseTrust: resultEligibilityMenu.data?.trust === "Allow",
    },
  } satisfies RequiredDataQueryResult;
}
